.container {
  align-items: center;
  border-radius: 4px;
  padding: 1.5rem;

  border: 1px solid rgba(34, 36, 38, 0.15);
  background: #fff;

  :global(.nocturnal-theme) & {
    border-color: rgba(255, 255, 255, 0.2);
    background-color: #2a2a2a;
  }
}

.innerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.content {
  display: flex;
  flex: 1;
  margin: 0 0.4rem 0 1rem;
}

.text {
  margin: 0 1.5rem;
  padding: 0;
}
