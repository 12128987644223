@import '../../../semantic/globals/site.variables';

.signupPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(
    to bottom,
    var(--secondary-background-color) 230px,
    #f9f9f9 0%
  );

  &.withEnvBanner {
    min-height: calc(100vh - @environmentBannerHeight);
  }
}
